import { Controller } from "@hotwired/stimulus"

export default class Modal extends Controller {
  static values = { removeOnQuit: String, emitEventOnQuit: String }

  quit() {
    if (this.emitEventOnQuitValue) {
      document.getElementById(this.emitEventOnQuitValue).dispatchEvent(new Event(this.emitEventOnQuitValue))
    }
    if (this.removeOnQuitValue === "true") {
      this.element.remove()
    } else {
      this.element.classList.add("-hidden")
    }
  }

  handleKeyup(event) {
    if (event.key === "Escape") this.quit()
  }
}
