import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"
const CHOOSEN_CLASS = "-choosen"

export default class Color extends Controller {
  static targets = ["modal", "input", "validation", "selection"]

  showColors(event) {
    this.modalTarget.classList.remove(HIDDEN_CLASS)

    event.preventDefault()
    event.stopPropagation()
  }

  blur(event) {
    if (!this.modalTarget.contains(event.relatedTarget)) {
      this.modalTarget.classList.add(HIDDEN_CLASS)
    }
  }

  select(event) {
    const color = event.currentTarget.dataset.colorNameValue
    this.inputTarget.value = color
    this.validationTargets.forEach((validation) => {
      validation.classList.remove(CHOOSEN_CLASS)
      if (validation.dataset.colorNameValue === color) {
        validation.classList.add(CHOOSEN_CLASS)
      }
    })
    this.selectionTarget.classList.remove(...this.selectionTarget.classList.value.split(" ").filter((c) => c.startsWith("-")))
    this.selectionTarget.classList.add(`-${color}`)
    this.modalTarget.classList.add(HIDDEN_CLASS)

    event.preventDefault()
    event.stopPropagation()
  }
}
